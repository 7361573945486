.xpa-size-slots {
    min-width: 500px;
    min-height: 400px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
}

.xpa-login-size {
    min-width: 250px;
    min-height: 250px;
}

.xpa-border-slots {
    border-radius: 5px;
    border: 1px solid #c7c3c3;
    max-width: 260px;
}
.xpa-border-slots:hover,
.xpa-border-slots:active,
.xpa-border-slots:focus {
    background: #eeeeee;
}
.xpa-btn-size {
    max-height: 30px;
    padding: 0;
}

.xpa-text-underline {
    text-decoration: underline;
    cursor: pointer;
}