.xpa-text-size-sm{
    font-size: smaller;
}

.xpa-disable:disabled{
    /* cursor: not-allowed; */
    pointer-events: none;
}
.xpa-disable{
    pointer-events: none;
}

