.bx--data-table-v2 thead th .text-center {
  display: block;
}

.bx--toolbar-search-container-active {
width: 100% !important;
}

.bx--batch-actions--active {
z-index: 1111;
}

.text-center .bx--table-header-label {
text-align: center !important;
}