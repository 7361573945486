.xopa__modal__body {
  display: flex;
}
.xopa__modal.job_details__modal {
  width: 70%;
  top: 70px;
  bottom: 100px;
}

.modal__user__info {
  display: flex;
  flex-direction: column;
}
.modal_profile__photo img {
  width: 150px;
  height: 150px;
  border-radius: 75px;
}
.xopa__modal__body {
  display: flex;
}

.profile_info {
  flex: 2;
  min-height: 100px;
}

.outcome_info {
  flex: 3;
  min-height: 100px;
}

.outcome_table {
  height: 400px;
  display: flex;
  flex-direction: column;
}

.outcome_table > div {
  flex: 1;
  display: flex;
  align-items: space-around;
  justify-content: space-around;
  border-bottom: 1px solid #eee;
}
.outcome_left {
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
}

.outcome_table > div:first-child {
  background-color: #eee;
}

.outcome_right.outcome_right_header{
  display: flex;
  justify-content: center;
  align-items: center;
}

.outcome_left {
  flex: 2;
}
.outcome_right {
  flex: 5;
}

.outcome_table > div {
  flex: 2;
}
.outcome_table > div:nth-child(1),
.outcome_table > div:nth-child(2) {
  flex: 1;
}

.outcome_left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outcome_right.outcome_right_header {
}
/* .outcome_right:not(.outcome_right_header)> div {
  flex: 1;
} */

.outcome_right {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.outcome_right > div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outcome_right_header > div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
}

.outcome_labels > div {
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
}

.outcome_labels > div > span {
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 7.5px;
  margin-right: 10px;
  margin-left: 10px;
}

.outcome_labels > div > span {
  margin-right: 10px;
}

.outcome_poor > span {
  background: #eb757b;
}

.outcome_labels .outcome_good > span {
  background-color: #efb132;
}
.outcome_labels .outcome_average > span {
  background-color: #7bf39c;
}
.outcome_labels .outcome_excellent > span {
  background-color: #4c9c07;
}

.outcome_labels {
  display: flex;
  justify-content: center;
  padding: 7px 0;
  margin-top: 10px;
  margin-top: 20px;
  display: flex;
  background-color: #eee;
}

/* left part user stat */

.modal__profile__pic > img {
  width: 150px;
  height: 150px;
  border-radius: 75px;
  display: block;
  margin: 22px auto 20px;
}

.modal__stats__table > div {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 5px 15px;
}

.modal__shortlist {
  display: flex;
  justify-content: space-around;
}
.modal__shortlist > div {
  flex: 1;
}

.modal__shortlist > .cell__shortlist__inner {
  display: block;
}
.modal__shortlist > .cell__shortlist__inner > div {
  display: inline-block;
  margin: 0 5px;
}

.modal__shortlist > div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}

.modal_states_right{
  width: 150px;
}