.xpa-reports-select-width {
    min-width: 250px;
}

.xpa-link-icon {
    color: white !important;
  }
  
.button-link:hover {
    text-decoration: none;
}