.xp-m__jobinfo .card{
  padding: 1rem;
  border: 0px;
}

.xp-m__jobinfo .card-subtitle {
  padding-top: 0.35rem;
}


.xp-m__jobinfo .jobInfoUpload div:first-child{
  z-index: 99999;
}

.jobInfo_mainstyle {
  margin-top: 72px;
}

@media (min-width: 768px) and (max-width: 1080px) {
  .jobInfo_mainstyle {
    margin-left: 18px;
  }
}