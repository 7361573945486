.heading-color {
    color: white !important;
  }

.bx--header-panel--expanded {
  width: 21rem !important;
}

.tab-color .bx--tabs__nav-link {
  color: white !important;
}

.xpa-avatar-width {
  min-width: 30px;
}

.loader-height {
  height: 100%;
}

.loader-height .bx--loading__stroke {
  stroke: #fff !important;
}