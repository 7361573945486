.select-input-width .bx--select-input__wrapper {
  width: 100% !important;
}

.req-feild {
  color: red;
}

@media screen and (min-width: 300px) and (max-width: 500px) {
 .small-devices-width {
   width: 100% !important;
 }
}

.xpa-profiling-data-card {
  background-color: lavender;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
}


