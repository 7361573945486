.toast {
    visibility: hidden;
}
.toast.show{
    visibility: visible;
    -webkit-animation:moveOpen 4s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
}

/* Safari and Chrome */
@-webkit-keyframes moveOpen
{
    from {-webkit-transform: translate(300px,0px);}
    10% {-webkit-transform: translate(0,0px);}
    12% {-webkit-transform: translate(0,0px);}
    16% {-webkit-transform: translate(0,0px);}
    80% {-webkit-transform: translate(0,0px);}
    85% {-webkit-transform: translate(0,0px);}
    to {-webkit-transform: translate(300px,0px);}
}
body .toast {
    position: absolute;
    top: 70px;
    right: 0px;
    width: 300px;
}