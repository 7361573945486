.CandidateDetails .bx--accordion__content {
  padding: 0% !important;
}

.candidateBio .bx--date-picker__icon {
  display: none;
}

.candidateBio.public .bx--date-picker__icon {
  display: block;
}

.candidateBio .bx--date-picker__input.flatpickr-input {
  margin-top: 0.25rem !important;
}

.candidateBio .bx--date-picker__icon ~ .bx--date-picker__input {
  padding-left: 2rem;
}

.candidateBio .numInput.cur-year {
  min-width: 52px !important;
}

.profile-skills .bx--tag {
  height: inherit !important;
}

.profile-skills .dv-star-rating-star {
  width: 20px !important;
  height: 20px !important;
}

.CandidateDetails .bx--slider__thumb {
  z-index: 1;
}

.start_label_div {
  border-left: 1px solid #bbb;
}

#max-date-pub-can {
  margin-top: 0.25rem;
}

.delete-color-text {
  color: red;
}

.select-input-width .bx--select-input {
  width: 100% !important;
  background-color: white !important;
  /* box-shadow: 0 1px 0 0 #525C6D; */
  color: #16233A;
  border-bottom: 1px solid transparent;
  border: none;
}