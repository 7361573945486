@media screen and (max-device-width: 768px) {

  .InternCandidate .bx--progress-step {
    display: none;
  }

  .InternCandidate li.bx--progress-step--current > li {
    content:'';
    display: block;
  }

  .InternCandidate li.bx--progress-step--current + li {
    content:'';
    display: block;
  }

  .InternCandidate li.bx--progress-step--current {
    display: block;
    content:'';
  }

}

.InternCandidate .progressIndicator .bx--progress-label {
  text-align: justify !important;
}

.InternCandidate .compitency .bx--modal-content {
  overflow-y: initial !important;
}

.InternCandidate .bx--inline-loading__text {
  margin-bottom: 0px;
}

.finland_MainPage{
  width: -webkit-fill-available;
}

.Job_preferences .bx--slider__thumb {
  z-index: 1;
}
.xpa-crono-cal > div {
  min-height: 200px !important;
}

.xpa-react-symbol .react-sweet-progress-symbol {
  width: 85px;
}

.xpa-profile-color {
  color: red;
}

.xpa-div-disable{
  pointer-events: none;
}

.xpa-div-disable:hover{
  cursor: not-allowed;
}