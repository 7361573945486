.candidates_container {
  flex: 1;
  /* padding: 1em; */
  /* background-color: #EEF1F5; */
}

.candidates_list {
  display: flex;
}
.candidates_container__header--top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
/* .candidates_container__header--top div:first-child {
  display: flex;
} */

.candidates_container__header--top div:first-child h3 {
  font-size: 1.6em;
  margin-right: 20px;
}

.candidates_container__header--top div:first-child input {
  border: none;
  outline: none;
  background: transparent;
  border-bottom: 1px solid #16233A;
}
.xopa__button {
  background-color: #632763;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  outline: none;
}
.xopa__button img {
  max-width: 60px;
}
.xopa__button:focus {
  outline: none;
}

.xopa__button span {
  text-transform: uppercase;
}

.xopa__button *:nth-child(2) {
  margin-left: 5px;
}

.candidates_container__header--subtitle {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.talentpool {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.talentpool h4 {
  font-size: 15px;
}

.talent__button.first {
  margin: 0 15px;
}

.talent__button {
  border: 2px solid #999;
  padding: 3px 10px;
  border-radius: 5px;
  cursor: pointer;
}

/* table */
.candidates_container__table table {
  margin-top: 20px;
  width: 100%;
}

.candidate__row,
.candidate__header {
  padding: 7px 20px;
  margin: 10px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}
/* 
.candidate__row {
  background-color: #fff;
  border-radius: 5px;
}
 */
.candidate__row span,
.candidate__header span {
  display: inline-block;
  box-sizing: border-box;
}

/* 14.2857 */
.candidate__name {
  width: 28.4%;
}

.job_id,
.candidate__date,
.candidate__stage {
  width: 16.285%;
}
/* 80 */
.candidate__internal,
.candidate__action {
  width: 10%;
}

.candidate__internal {
}
.candidate__stage {
}
.candidate__action {
}

.candidate__action i {
  font-size: 1.3em;
  display: inline-block;
  padding: 0 13px;
  cursor: pointer;
}

.candidate__action .fa {
  color: #eb757b;
}
.nopointer {
  cursor: auto !important;
}

.opacity0 {
  opacity: 0 !important;
}
