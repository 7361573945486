.double.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

font-size: 20px;
}

.double.strike > span {
  position: relative;
  display: inline-block;
}

.double.strike > span:before,
.double.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  /* Here is the modification */
  border-top: 4px double#6c757d;
}

.double.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.double.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap; 
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #6c757d;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.InterviewStatus .bx--inline-notification {
  margin: 0px;
}

/* .InterviewStatus .accordionhide, .bx--accordion__title {
  width: 100%;
  margin: 0px;
}

.InterviewStatus .accordionhide .bx--accordion__item {
  width: 100%;
  border-bottom: none !important;
  border-top: none !important;
}

.InterviewStatus .accordionhide .bx--accordion__item .bx--accordion__arrow {
  transform: rotate(90deg);
}

.InterviewStatus .accordionhide .bx--accordion__item--active.bx--accordion__item .bx--accordion__arrow {
  transform: rotate(270deg);
}

.InterviewStatus .accordionhide .bx--accordion__heading {
  display: flex;
  flex-direction: row-reverse;
} */

.InterviewStatus .successImg {
  border-left: 3px solid #7de47d;
}

.InterviewStatus .rejectImg {
  border-left: 3px solid #f18686;
}

.InterviewStatus .waitingImg {
  border-left: 3px solid #ecba5c;
}

.InterviewStatus .our-accordion a:first-child {
  color: #5558AF !important;
  background-color: transparent !important;
  cursor: pointer !important;
  text-decoration: none !important;
}

.InterviewStatus .our-accordion a:first-child:hover {
  text-decoration: underline !important;
}

.InterviewStatus .bx--structured-list {
  min-width: unset !important;
}