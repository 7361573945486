.xpa-pg-circle {
   font-size: 10px;
   margin: 0;
   position: relative;
   padding: 0;
   width: 5em;
   height: 5em;
   background-color: #F2E9E1; 
   border-radius: 50%;
   line-height: 5em;
}

.xpa-pg-circle:after {
    border: none;
    position: absolute;
    top: 0.35em;
    left: 0.35em;
    text-align: center;
    display: block;
    border-radius: 50%;
    width: 4.3em;
    height: 4.3em;
    background-color: white;
    content: " ";
}
/* Text inside the control */
.xpa-pg-circle span {
    font-weight: bold;
    position: absolute;
    line-height: 5em;
    width: 5em;
    text-align: center;
    display: block;
    color: #53777A;
    z-index: 2;
}
.left-half-clipper { 
   /* a round circle */
   border-radius: 50%;
   width: 5em;
   height: 5em;
   position: absolute; /* needed for clipping */
   clip: rect(0, 5em, 5em, 2.5em); /* clips the whole left half*/ 
}
/* when p>50, don't clip left half*/
.xpa-pg-circle.over50 .left-half-clipper {
   clip: rect(auto,auto,auto,auto);
}
.value-bar {
   /*This is an overlayed square, that is made round with the border radius,
   then it is cut to display only the left half, then rotated clockwise
   to escape the outer clipping path.*/ 
   position: absolute; /*needed for clipping*/
   clip: rect(0, 2.5em, 5em, 0);
   width: 5em;
   height: 5em;
   border-radius: 50%;
   border: 0.45em solid #53777A; /*The border is 0.35 but making it larger removes visual artifacts */
   /*background-color: #4D642D;*/ /* for debug */
   box-sizing: border-box;
  
}
/* Progress bar filling the whole right half for values above 50% */
.xpa-pg-circle.over50 .first50-bar {
   /*Progress bar for the first 50%, filling the whole right half*/
   position: absolute; /*needed for clipping*/
   clip: rect(0, 5em, 5em, 2.5em);
   background-color: #53777A;
   border-radius: 50%;
   width: 5em;
   height: 5em;
}
.xpa-pg-circle:not(.over50) .first50-bar{ display: none; }


/* Progress bar rotation position */
.xpa-pg-circle.p0 .value-bar { display: none; }
.xpa-pg-circle.p1 .value-bar { transform: rotate(4deg); }
.xpa-pg-circle.p2 .value-bar { transform: rotate(7deg); }
.xpa-pg-circle.p3 .value-bar { transform: rotate(11deg); }
.xpa-pg-circle.p4 .value-bar { transform: rotate(14deg); }
.xpa-pg-circle.p5 .value-bar { transform: rotate(18deg); }
.xpa-pg-circle.p6 .value-bar { transform: rotate(22deg); }
.xpa-pg-circle.p7 .value-bar { transform: rotate(25deg); }
.xpa-pg-circle.p8 .value-bar { transform: rotate(29deg); }
.xpa-pg-circle.p9 .value-bar { transform: rotate(32deg); }
.xpa-pg-circle.p10 .value-bar { transform: rotate(36deg); }
.xpa-pg-circle.p11 .value-bar { transform: rotate(40deg); }
.xpa-pg-circle.p12 .value-bar { transform: rotate(43deg); }
.xpa-pg-circle.p13 .value-bar { transform: rotate(47deg); }
.xpa-pg-circle.p14 .value-bar { transform: rotate(50deg); }
.xpa-pg-circle.p15 .value-bar { transform: rotate(54deg); }
.xpa-pg-circle.p16 .value-bar { transform: rotate(58deg); }
.xpa-pg-circle.p17 .value-bar { transform: rotate(61deg); }
.xpa-pg-circle.p18 .value-bar { transform: rotate(65deg); }
.xpa-pg-circle.p19 .value-bar { transform: rotate(68deg); }
.xpa-pg-circle.p20 .value-bar { transform: rotate(72deg); }
.xpa-pg-circle.p21 .value-bar { transform: rotate(76deg); }
.xpa-pg-circle.p22 .value-bar { transform: rotate(79deg); }
.xpa-pg-circle.p23 .value-bar { transform: rotate(83deg); }
.xpa-pg-circle.p24 .value-bar { transform: rotate(86deg); }
.xpa-pg-circle.p25 .value-bar { transform: rotate(90deg); }
.xpa-pg-circle.p26 .value-bar { transform: rotate(94deg); }
.xpa-pg-circle.p27 .value-bar { transform: rotate(97deg); }
.xpa-pg-circle.p28 .value-bar { transform: rotate(101deg); }
.xpa-pg-circle.p29 .value-bar { transform: rotate(104deg); }
.xpa-pg-circle.p30 .value-bar { transform: rotate(108deg); }
.xpa-pg-circle.p31 .value-bar { transform: rotate(112deg); }
.xpa-pg-circle.p32 .value-bar { transform: rotate(115deg); }
.xpa-pg-circle.p33 .value-bar { transform: rotate(119deg); }
.xpa-pg-circle.p34 .value-bar { transform: rotate(122deg); }
.xpa-pg-circle.p35 .value-bar { transform: rotate(126deg); }
.xpa-pg-circle.p36 .value-bar { transform: rotate(130deg); }
.xpa-pg-circle.p37 .value-bar { transform: rotate(133deg); }
.xpa-pg-circle.p38 .value-bar { transform: rotate(137deg); }
.xpa-pg-circle.p39 .value-bar { transform: rotate(140deg); }
.xpa-pg-circle.p40 .value-bar { transform: rotate(144deg); }
.xpa-pg-circle.p41 .value-bar { transform: rotate(148deg); }
.xpa-pg-circle.p42 .value-bar { transform: rotate(151deg); }
.xpa-pg-circle.p43 .value-bar { transform: rotate(155deg); }
.xpa-pg-circle.p44 .value-bar { transform: rotate(158deg); }
.xpa-pg-circle.p45 .value-bar { transform: rotate(162deg); }
.xpa-pg-circle.p46 .value-bar { transform: rotate(166deg); }
.xpa-pg-circle.p47 .value-bar { transform: rotate(169deg); }
.xpa-pg-circle.p48 .value-bar { transform: rotate(173deg); }
.xpa-pg-circle.p49 .value-bar { transform: rotate(176deg); }
.xpa-pg-circle.p50 .value-bar { transform: rotate(180deg); }
.xpa-pg-circle.p51 .value-bar { transform: rotate(184deg); }
.xpa-pg-circle.p52 .value-bar { transform: rotate(187deg); }
.xpa-pg-circle.p53 .value-bar { transform: rotate(191deg); }
.xpa-pg-circle.p54 .value-bar { transform: rotate(194deg); }
.xpa-pg-circle.p55 .value-bar { transform: rotate(198deg); }
.xpa-pg-circle.p56 .value-bar { transform: rotate(202deg); }
.xpa-pg-circle.p57 .value-bar { transform: rotate(205deg); }
.xpa-pg-circle.p58 .value-bar { transform: rotate(209deg); }
.xpa-pg-circle.p59 .value-bar { transform: rotate(212deg); }
.xpa-pg-circle.p60 .value-bar { transform: rotate(216deg); }
.xpa-pg-circle.p61 .value-bar { transform: rotate(220deg); }
.xpa-pg-circle.p62 .value-bar { transform: rotate(223deg); }
.xpa-pg-circle.p63 .value-bar { transform: rotate(227deg); }
.xpa-pg-circle.p64 .value-bar { transform: rotate(230deg); }
.xpa-pg-circle.p65 .value-bar { transform: rotate(234deg); }
.xpa-pg-circle.p66 .value-bar { transform: rotate(238deg); }
.xpa-pg-circle.p67 .value-bar { transform: rotate(241deg); }
.xpa-pg-circle.p68 .value-bar { transform: rotate(245deg); }
.xpa-pg-circle.p69 .value-bar { transform: rotate(248deg); }
.xpa-pg-circle.p70 .value-bar { transform: rotate(252deg); }
.xpa-pg-circle.p71 .value-bar { transform: rotate(256deg); }
.xpa-pg-circle.p72 .value-bar { transform: rotate(259deg); }
.xpa-pg-circle.p73 .value-bar { transform: rotate(263deg); }
.xpa-pg-circle.p74 .value-bar { transform: rotate(266deg); }
.xpa-pg-circle.p75 .value-bar { transform: rotate(270deg); }
.xpa-pg-circle.p76 .value-bar { transform: rotate(274deg); }
.xpa-pg-circle.p77 .value-bar { transform: rotate(277deg); }
.xpa-pg-circle.p78 .value-bar { transform: rotate(281deg); }
.xpa-pg-circle.p79 .value-bar { transform: rotate(284deg); }
.xpa-pg-circle.p80 .value-bar { transform: rotate(288deg); }
.xpa-pg-circle.p81 .value-bar { transform: rotate(292deg); }
.xpa-pg-circle.p82 .value-bar { transform: rotate(295deg); }
.xpa-pg-circle.p83 .value-bar { transform: rotate(299deg); }
.xpa-pg-circle.p84 .value-bar { transform: rotate(302deg); }
.xpa-pg-circle.p85 .value-bar { transform: rotate(306deg); }
.xpa-pg-circle.p86 .value-bar { transform: rotate(310deg); }
.xpa-pg-circle.p87 .value-bar { transform: rotate(313deg); }
.xpa-pg-circle.p88 .value-bar { transform: rotate(317deg); }
.xpa-pg-circle.p89 .value-bar { transform: rotate(320deg); }
.xpa-pg-circle.p90 .value-bar { transform: rotate(324deg); }
.xpa-pg-circle.p91 .value-bar { transform: rotate(328deg); }
.xpa-pg-circle.p92 .value-bar { transform: rotate(331deg); }
.xpa-pg-circle.p93 .value-bar { transform: rotate(335deg); }
.xpa-pg-circle.p94 .value-bar { transform: rotate(338deg); }
.xpa-pg-circle.p95 .value-bar { transform: rotate(342deg); }
.xpa-pg-circle.p96 .value-bar { transform: rotate(346deg); }
.xpa-pg-circle.p97 .value-bar { transform: rotate(349deg); }
.xpa-pg-circle.p98 .value-bar { transform: rotate(353deg); }
.xpa-pg-circle.p99 .value-bar { transform: rotate(356deg); }
.xpa-pg-circle.p100 .value-bar { transform: rotate(360deg); }