@media screen and (min-device-width: 768px) {
  .publicComponentDiv {
    padding-top: 63px !important;
  }
  
}

@media screen and (max-device-width: 768px) {
  .publicComponentDiv {
    padding-top: 63px !important;
  }  
}