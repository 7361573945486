.profile-container {
    background: #fff;
    text-align: center;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
    margin-bottom: 20px;
}
.justify-text{
    text-align: justify;
}
.upload-span{
    color: #7f7f7f;
}
.profile-img * {
  cursor: pointer;
}
.profile-img img {
  padding: 100px;
}
.xpa-gtp-option {
  line-height: 1.5rem;
  width: 4rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: opacity .25s;
}
.btn.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}
.btn-toggle {
  margin: 0 0 0 1rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  border-radius: 1.5rem;
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
  outline: none;
}

.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #fff;
  transition: left .25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left .25s;
}
.btn-toggle.active:before {
  opacity: .5;
}
.btn-toggle.active:after {
  opacity: 1;
}
.btn-toggle.active {
  background-color: #632763;
}


.bx--tabs__nav-item {
  margin-right: 1rem;
}