.xpa-left-nav-color-size {
    color: white !important;
    font-size: large !important;
  }
  
  .xpa-side-bar-toggle {
    border-radius: unset !important;
    border: 0px;
  }
  
  .xpa-label-cursor {
    cursor: pointer;
  }
  
  .xpa-left-nav-list {
    padding: .5rem 0rem !important;
    margin: 0.3rem !important;
  }
  
  @media (min-width: 770px) {
    .xpa-left-nav-list-name-size {
      font-size: 1rem !important;
    }
  }
  
  @media (min-width: 320px) and (max-width: 770px) {
    .xpa-left-nav-min-width {
      font-size: x-small !important;
    }
  }
  
  .xpa-toggled-view-font-size {
    font-size: smaller !important;
  }
  
  .xpa-nav-right-list-products {
    font-size: 1rem !important;
  }
  
  .xpa-nav-right-list-help-section {
    font-size: 1.3rem !important;
  }
  
  .xpa-collapse-item {
    color: #3a3b45 !important;
    text-decoration: none !important;
  }
  
  .xpa-nav-right-list-bells {
    font-size: 1.1rem !important;
  }
  
  .xpa-nav-item-list-highlighted {
    background-color: rgba(255, 255, 255, .1);
  }
  
  .xpa-yuvo-color {
    background-color: #01796f;
  }

  .xpa-logo-width {
    width: 140px;
  }

  .xpa-logo-width-toggled {
    width: 135px;
  }

  .xpa-logo-backgorund-color {
    background-color: white;   background-color: white;
  }