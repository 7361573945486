.preview-label{
    display: inline-block;
    width: 30%;
}
.preview-value{
    display: inline-block;
    width: 70%;
    text-align: justify;
}
.preview-value input{
    width: inherit;
    border: none;
}
@media (min-width: 1024px) {
    .xpa-m-preview .bx--modal-container{
        width: 80%;
        max-width: 80%;
    }
}
