.xp-analytics .bx--accordion__title {
    width: 100%;
}
.xp-analytics .bx--accordion__content {
    background: #F3F4F5;
}

.chart-pipeline{
    margin:auto;
    width: 95%;
    height: 10rem;
  }
.chart-pipeline     svg {
    height: 100%;
    width: 100%;
  }
.xp-analytics .bx--accordion__item {
    border: none
}

.xp-font-sz {
  font-size: 14px;
}

.xp-font-sz-link {
  font-weight: normal;
  font-size: 15px;
}

.xpa-analytics-avatar-width {
  min-width: 60px;
}