@media screen and (min-device-width: 768px) {
    
    .xpav-rpt .SearchGtp{
      width:75%;
    }

    .talentpool_button {
      width: 150px;
    }

  }
/* 
  @media screen and (max-device-width: 768px) {
    
    .xpav-rpt .resumeUpload{
        margin-right: 70px;
        margin-top: 8px;
    }
  } */

.rtp_row td:first-child {
  width: 65px !important;
  /* background-color: white; */
}

.rtp_row td{
  border-top: none;
}
.rtp_row th{
  background-color: white;
}

.xpa-talent-pool--wrapper section {
  height: 56px;
}