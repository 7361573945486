
.edit-btn {
    width: max-content;
    position: absolute;
    right: 10px;
    bottom: 0px;
}

.xp-m__job--container {
    min-height: 30rem;
}
.xpa-cand-info--tabs .bx--tabs__nav {
  margin-left: 0px;
}

.xpa-format--pre-content.jdesc p {
  margin-bottom: 0px !important;
}

.xpa-format--pre-content.jdesc ol, li {
  list-style: inherit !important;
  margin-left: 0.25rem !important;
}

.joblist__cnt--total {
  background: #d3f6f3;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
}

.joblist__cnt--short {
  background: #fff3d5 !important;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
}

.joblist__cnt--rejected {
  background: #ffe6eb !important;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
}

.joblist__cnt--hired {
  background: #d6f8b8 !important;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
}

.xp-m__session--container {
  min-height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 90vh;
}