.analytics-skills {
    border-right: 1px solid black;
    height: '-webkit-fill-available';
}

.analytics-jobs-card {
    background-image: linear-gradient(0deg,#dad4ec 0,#dad4ec 1%,#f3e7e9);
}

.analytics-applications-card {
    background-image: linear-gradient(60deg,#abecd6,#fbed96);
}

.analytics-tile {
    min-width: 200px;
}