.xpa--login .nav-tabs .nav-item {
  border: none !important;
}
.xpa--login .form-signin {
  border: none;
  border-radius: 0px;
}
.xpa--login .nav-tabs {
  border-bottom: 0px;
}
.xpa--login .form-signin {
  width: 330px;
}

.bg-trans {
  position: absolute;
  width: 100%;
  background-color: #cdd1d4;
  /* background-image: linear-gradient(to top, #d43f8d 0%, #086acc 100%); */
  height: 100%;
  left: 0;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
  height: 269px;
}
.form-signin.error {
  height: 311px;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.bx--text-input{
  min-width: 100%;
}


.registerCandidate .toggleFulltimeIntern{
  max-width: 36px;
}

.finlandLanding {
  min-height: -webkit-fill-available;
}

.finlandLanding .left-side {
  background: #6265bc;
}

.finlandLanding .right-side {
  background: #ffff;
}

.finlandLanding .xpa--login .form-signin {
  height: auto;
}