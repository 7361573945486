.xpa-job-analytics--card {
    border: none;
    box-shadow: 0px;
    border-radius: 5px
}

.xpa-job-analytics--card table thead {
    background: #F3F4F5
}

.xpa-job-analytics--card--grey {
    background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);
}
.xpa-job-analytics--card--grey .numiconcard__number--number, .xpa-job-analytics--card--grey .numiconcard__number--title{
    color: #5D478B;
}


.xpa-job-analytics--card--orange {
    background-image: linear-gradient(-225deg, #7DE2FC 0%, #B9B6E5 100%);
}
.xpa-job-analytics--card--orange .numiconcard__number--number, .xpa-job-analytics--card--orange .numiconcard__number--title {
    color:#104E8B;
}

.xpa-job-analytics--card--green {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.xpa-job-analytics--card--green .numiconcard__number--number, .xpa-job-analytics--card--green .numiconcard__number--title{
    color: #008080;
}
