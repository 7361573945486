.xpa--login .nav-tabs .nav-item {
  border: none !important;
}
.xpa--login .form-signin {
  border: none;
  border-radius: 0px;
}
.xpa--login .nav-tabs {
  border-bottom: 0px;
}
.xpa--login .form-signin {
  width: 330px;
}

.bg-trans {
  position: absolute;
  width: 100%;
  background-color: #cdd1d4;
  /* background-image: linear-gradient(to top, #d43f8d 0%, #086acc 100%); */
  height: 100%;
  left: 0;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
  height: 269px;
}
.form-signin.error {
  height: 311px;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.bx--text-input{
  min-width: 100%;
}


.registerCandidate .toggleFulltimeIntern{
  max-width: 36px;
}

.login-page {
  min-height: -webkit-fill-available;
  min-height: 100%
}

.login-page .left-side {
  background: #5558AF;
  background-size: cover;
  background-position: 50%;
  background-color: '#5558AF';
  color: white !important;
  min-height: -webkit-fill-available;
}

.login-page .right-side {
  background: #80a2ce;
  background-position: 50%;
  background-size: cover;
  color: white;
  min-height: -webkit-fill-available;
}

.student-image {
  background-image: url('../../../assets/login-images/student-img.jpg');
  background-position: center !important;
  color: white !important;
}

.company-mask, .institution-mask, .student-mask {
  background: rgba(167,150,0,.3);
}

.background-masking {
  width: 100%;
  height: 100vh;
}

.institution-image {
  background-image: url('../../../assets/login-images/institution-img.jpg');
  color: white !important;
}

.company-image {
  background-image: url('../../../assets/login-images/company-img.jpg');
  color: white !important;
}

.login-page .middle-center {
  background: #6299e0;
  background-size: cover;
  color: white;
  min-height: -webkit-fill-available;
}

.login-page .xpa--login .form-signin {
  height: auto;
}

.collegelogin .login-page .left-side {
  background: #6299e0;
  background-size: cover;
  /* color: white; */
}

.companylogin.login-page .left-side {
  background: #80a2ce;
  background-size: cover;
  /* color: white; */
}

body {
  height: 100% !important;
}

.all-login.login-page .left-side .bx--btn--secondary:hover {
  background-color: #2b2fa1;
}

.all-login.login-page .middle-center .bx--btn--secondary:hover {
  background-color: #3976c4;
}

.all-login.login-page .right-side .bx--btn--secondary:hover {
  background-color: #3d669c;
}

.studentlogin.login-page .bx--btn--secondary:hover {
  background-color: #2b2fa1;
}

.collegelogin.login-page .bx--btn--secondary:hover {
  background-color: #3976c4;
}

.companylogin.login-page .bx--btn--secondary:hover {
  background-color: #3d669c;
}

.invalid {
  border-bottom: 2px solid red;
}

.invalid-login-color {
  color: #C50E2E;
}

.xpa-form-item .bx--form-item {
  margin-bottom: 0%;
}

.geip-image-resolution {
  width: 225px;
  height: 100px;
}

.font-color-heading {
  color: black !important;
}

.std_heding{
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase!important;
  font-family: 'Roboto', sans-serif !important;
  color: black !important;
}

.main_header_color{
  color:#ffff !important;
}
.mx_logo{
  width:66%;
  justify-content: center !important;
}
.logo_area{
  width: 100% !important;
  justify-content: center !important;
  position: relative !important;
}
.btn_disp{
  /* display: inline !important; */
  display: inline-block !important;
  position: relative;
    top: -3px;
    right: 18px;
}
.btn_pos{
  position: relative !important;
  bottom:15.5rem !important;
}
.bt_disp_pos{
  position: relative;
  top:5px !important;
  right:4px !important;

}
.st_btn_main{
  display: inline-block !important;
}
.lgbtn_rgbtn{
  position: relative !important;
    top: -8px !important;
    right: 18px !important;
}
.lgbtn_rgbtn_pos{
  position: relative !important;
  bottom:16.5rem !important;
}

.logo_pos{
  position: relative !important;
  bottom: 5rem !important;
  left: 14rem !important;
}

.bx_post{
  position: relative !important;
  right: 7rem !important;
  top: 4rem !important;
  color: #000 !important;
}
.cont_txt{
  color: #000 !important;
  font-weight: 600;
  line-height: 1.2;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif !important;
}
.containe_size{
  /* margin-right: 1rem important; */
  width:28rem;
}

.inp_box{
  width: 23rem !important; 
  margin-left: 1.6rem;
}
.log_size_reg{
  width: 90%;
}

.reg_sub_head {
  font-size: 1.75rem;
  line-height: 0.25;
}
/* Registartion page first */
.full_area{
  width: 100%;
  background-color:#ffff;
}
.area_icon_width{
  width: 28% !important;
  
}
.reg_head_form{
  position: relative !important;
  text-align: center !important;
}
.form_width{
  width: 800px;
  /* text-align: center !important;
  justify-content: center !important; */
}
.forrm_box_pos{
  position: relative;
  left:88px;
}
.area_btn_pos{
  position: relative;
  left: 194px;
}

