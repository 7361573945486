.xpa-invalid-ddb{
    /* outline: 2px solid red; */
    border: 2px solid red;
    border-radius: 4px;
}

.xpa-ddb-error-msg{
    display: block;
    color: #da1e28;
    font-weight: 400;
    overflow: visible;
    max-height: 12.3rem;
}