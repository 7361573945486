@media screen and (min-width: 1501px) {
  .header-width-switch-company {
      width: 15%;
  }
  
  .header-width-actions {
      width: 10%;
  }
}

@media screen and (min-width: 314px) and (max-width: 1500px) {
  .header-small-sc {
      width: 25%;
  }
  
  .header-small-actions {
      width: 10%;
  }
}

.current-lang {
  font-weight: 400;
  color: white;
  font-size: inherit;
}

.company-name-width {
  min-width: 200px;
}

.right-action-color {
  color: darkgray !important;
}