ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 18px !important;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline  li {
  margin: 0px 0;
  padding-left: 10px;
}
ul.timeline li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #DEE0E3;
  left: 19px;
  width: 20px;
  height: 20px;
  z-index: 400;
  margin-left: 15px;
}

.bx--date-picker__icon {
  z-index: 0;
}