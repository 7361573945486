.xpa-modal--bg {
    position: fixed;
    z-index: 11111;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .xpa-modal {
    position: fixed !important;
    top: 50px;
    bottom: 30px;
    width: 60%;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    overflow-y: hidden;
    overflow-x: hidden;
    z-index: 1000;

  }
  
  .xpa-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #086acc;
  }
  
  .xpa-modal__header h3 {
    flex: 1;
    padding: 1rem 0.5rem 0.5rem 1rem;
    font-size: 1em;
    color: white;
  }
  
  .xpa-modal__header span {
    color: white;
    font-size: 1em;
    padding: 1em;
    cursor: pointer;
  }
  
  .xpa-modal--card {
    cursor: pointer;
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  
  .xpa-modal__container {
    padding: 1em;
  }
  
  .xpa-modal__body {
    padding: 0.5em 1.5em;
    overflow-y: scroll;
    height: 100%;
  }
  
  .xpa-modal__body > div:last-of-type{  
    padding-bottom: 75px
  }
  /* modal sections styling */
  
  .xpa-modal__section {
    padding: 0.4em 0px;
  }

  .xpa-modal__section  h5{
    font-size: 0.85rem;
  }

  /* .xpa-modal__section > h3 {
    font-size: 1.3em;
    text-transform: capitalize;
  } */
  
  .xpa-modal__section > p {
    padding-top: 10px;
    font-size: 0.8rem;
  }
  .xpa-modal__section > ul {
    padding-top: 10px;
    padding-left: 15px;
  }
  .xpa-modal__section > ul {
    list-style: none;
  }