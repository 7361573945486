.xpa-disable-cursor {
  cursor: not-allowed;
}

.xpa-body-background {
  background-color: #eeeeec;
}

.mx-auto {
  width: 66%;
}

@media only screen and (max-width: 460px) {
  .xs-device {
    width: 100% !important;
  }
}

@media only screen and (min-width: 550px) and (max-width: 710px) {
  .sm-device {
    width: 75% !important;
  }
}

@media only screen and (min-width: 711px) and (max-width: 810px) {
  .lg-device {
    width: 65% !important;
  }
}

@media only screen and (min-width: 811px) and (max-width: 1408px) {
  .x-lg-device {
    width: 47% !important;
  }
}

@media only screen and (min-width: 1061px) {
  .large-device {
    width: 50% !important;
  }
}

.main-page-alignment {
  margin: auto;
  height: auto;
  min-height: calc(100vh - 40px);
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .reg-page {
    max-width:540px
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .reg-page {
    max-width:768px
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .reg-page {
    max-width:960px
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .reg-page {
    max-width:1200px
  }
}
@media (min-width: 1400px) {
  .reg-page {
    max-width:1400px
  }
}

.invalid-msg {
  display: block;
  color: #da1e28;
  font-weight: 400;
  font-size: .75rem;
  letter-spacing: .32px;
  margin: .25rem 0 0;
  overflow: visible
}

.xpa-customize-checkbox .bx--checkbox-label{
  margin-bottom: 0;
}

.xpa-customize-checkbox .invalid-msg {
  display: block;
  color: #da1e28;
  font-weight: 400;
  font-size: .85rem;
  letter-spacing: .32px;
  margin: 0 0 0;
  overflow: visible
}