.form-heading-font {
  font-size: larger;
}

.rating-scale-border {
  border-right: 2px solid black;
}

.rating-size {
  font-size: x-large;
  font-weight: bold;
}