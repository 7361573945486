.finland_MainPage {
  overflow-x: hidden;
}

.section-align-center {
  margin: auto;
  height: auto;
  min-height: calc(100vh - 40px);
}

/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) {
  .main-application-view {
    max-width: 540px
  }
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .main-application-view {
    max-width: 768px
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .main-application-view {
    max-width: 960px
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .main-application-view {
    max-width: 1200px
  }
}

@media (min-width: 1400px) {
  .main-application-view {
    max-width: 1400px
  }
}

/* @media screen and (min-width: 768px) {
  .navbar-brand {
    max-width: 205px;
  };
}

@media screen and (min-width: 1366px) {
  .navbar-brand {
    min-width: 230px;
  }
}

@media screen and (min-width: 1920px) {
  .navbar-brand {
    min-width: 320px;
  }
}

@media screen and (min-width: 2220px) {
  .navbar-brand {
    min-width: 360px;
  }
}

@media screen and (min-width: 2560px) {
  .navbar-brand {
    min-width: 380px;
  }
} */

/* @media screen and (min-width: 768px) and (max-width: 820px) {
  .globalsearch {
    display: none;
  };
 } */

.navbar-fixed {
  margin-right: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 999;
}

.mainpagediv {
  margin-bottom: 2%;
  margin-left: 3%;
}

@media only screen and (max-width: 575px) {
  .mainpagediv {
    top: 130px;
    margin-left: 0%;
  }
}

@media only screen and (min-width: 370px) and (max-width: 576px) {
  .mainpagediv {
    top: 48px;
    margin-left: 0%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 992px) {
  .mainpagediv {
    top: 48px;
    margin-left: 0%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1335px) {
  .mainpagediv {
    top: 48px;
    margin-left: 5%;
  }
}

@media only screen and (min-width: 1336px) and (max-width: 1450px) {
  .mainpagediv {
    top: 48px;
    margin-left: 3%;
  }
}

@media only screen and (min-width: 1450px) {
  .mainpagediv {
    top: 48px;
    margin-left: 2.5%;
  }
}

.one-product {
  background: rgb(211, 211, 211, 0.3) !important;
  cursor: pointer !important;
  width: 100px !important;
  height: 90px !important;
}

.one-product:hover {
  background-color: lightblue !important;
}

.bx--tooltip {
  background-color: rgba(0, 0, 0, 0.9) !important;
  padding: 0px !important;
  align-items: center;
  color: white;
  padding: 4px !important;
  border: none;
  padding-left: 8px !important;
}

.bx--tooltip .bx--tooltip__caret {
  background-color: rgba(0, 0, 0, 0.9) !important;
  border: none;
}

.d-hotline.h-btn.animated.zoomIn, .fc-widget-small {
  z-index: 1 !important;
}

#fc_frame {
  z-index: 1 !important;
}

.bx--tooltip__label {
  color: white !important;
}

.dropdown .css-151xaom-placeholder {
  color: hsl(0, 0%, 70%);
}

.subitems-card {
  background-color: #fff;
  border-top: 1px solid #b9b9b9;
  box-shadow: 0 16px 24px 0 rgba(0, 0, 0, .08);
  min-height: 9.8125rem;
  left: 0;
  position: fixed;
  top: 0px;
  z-index: 1111;
  width: 100%;
  border-bottom: 2px solid rgb(75, 32, 64);
}

.simulator-card {
  right: 50px;
  background-color: #fff;
  border-top: 1px solid #b9b9b9;
  min-height: 9.8125rem;
  position: fixed;
  top: 48px;
  z-index: 1111;
  border-radius: .5rem;
  width: 50%;
  height: 80%;
  overflow: scroll;
}

.xpa-tab {
  /* display: inline-block;
  margin-bottom: 0;
  padding: .5rem;
  background-color: lightgrey; */
  display: inline-block;
  margin-bottom: 0;
  padding: .5rem .5rem .3rem .5rem;
}

.xpa-tab:hover {
  cursor: pointer;
}

/* .xpa-simulate {
  background-color: black !important;
} */

.xpa-selected-tab {
  border-bottom: 2px solid blue;
  /* background-color: #f4f4f4;
  box-shadow: 1px 1px lightgray; */
  /* border-top: 1px;
  border-left: 1px;
  border-right: 1px;
  border-style: solid;
  border-color: lightgray; */
}

.xpa-non-selected-tab {
  border-bottom: 2px solid lightgrey;
}

.xpa-table .bx--data-table-container {
  /* border-left: 1px;
  border-right: 1px; */
  /* border-bottom: 1px; */
  /* border-style: solid;
  border-color: lightgray; */
  padding-top: 0rem;
}

.xpa-bdr--left {
  border-left: 1px solid rgb(238, 238, 238);
}

.icon-size {
  font-size: 3rem !important;
}

@media only screen and (min-width: 1313px) {
  .vertical-line {
    border-right: 2px solid black;
    height: 6rem;
  }
}

.app-name-color {
  color: white !important;
}

p.bx--file-filename {
  margin-bottom: 0;
}

.bx--accordion__content .bx--file__selected-file:last-child {
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 1050px) and (max-width: 1207px) {
  .hide-side-nav {
    display: none !important;
  }
}

.xpa-top-header--logo-room {
  max-height: 2.5rem;
}