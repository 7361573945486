
.joblist-layout .sui-layout-header {
  padding: .5rem;
}
.sui-layout-sidebar {
  width: 20% !important;
  padding: 32px 32px 0 0 !important;
}

.sui-layout-main {
  width: 80% !important;
  padding: 32px 0 32px 32px !important;
}

.bx--tabs__nav-item {
  margin-right: 0 !important;
}

.tabs-width-fix a{
    display: block;
    width: 100%;
}

.card-spacing{
  margin: 10px;
}

.joblist {
  display: flex;
  flex-direction: row;
}

.filter-options__container{
  margin-top: 100px;
  padding: 20px;
}

.sort-header {
  cursor: pointer;
}

.sort-header svg{
  position: relative;
  top: 5px;
  margin-left: 10px;
}

.joblist__container {
  flex: 1;
  /* background: #EEF1F5; */
}

.joblist__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 0px;
}

.joblist__header--left {
  display: flex;
}

/* .joblist__header--left h3 {
  font-size: 1.7em;
  margin-right: 2em;
  color: #16233A;
} */

.joblist__header__search {
  border: none;
  outline: none;
  padding: 0 .5em;
  background-color: transparent;
  border-bottom: 1px solid #632763;
  color: #632763;
}

.joblist__header__search::placeholder{
  color: #632763;
}

.newjob {
  border: none;
  outline: none;
  border-radius: 5px;
  display: flex;
  padding: 0.3em 0.7em;
  background-color: #632763;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
}
.newjob:hover {
  background-color: #842b84;
  color: white;
  text-decoration: none;
}

.joblist__header--right {
}
.joblist__content {
  margin-top: 1em;
}

.joblist__content table {
  background: white;
}


/* job list table */
.joblist__table {
  width: 100%;
}
.joblist__table--header {
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    color: rgba(0,0,0,.54);
    text-transform: uppercase;
    font-size: .7em;
    vertical-align: text-top;
}
.joblist__desc {
  display: inline-block;
  width: 100%;
}

.job_keywords {
  display: flex;
  margin: 10px 0;
}

.job_keyword:first-child {
  margin-right: 7px;
}

.job_keyword {
  border: 1px solid #16233A;
  color: #16233A;
  border-radius: 5px;
  padding: 0px 8px;
  cursor: pointer;
}

.job_keyword span {
  font-size: 0.9em;
}

.joblist__tr td {
  padding: 0 1em;
}

.joblist__icon i {
  font-size: 1.4em;
}

.joblist__icon {
  cursor: pointer;
}
.joblist__icon i {
  transition: all 0.5s ease-in-out;
}

.joblist__icon--expanded i {
  transform: rotate(90deg);
}
.joblist__tr--expanded .joblist__tr__details {
  display: '';
}

.joblist__tr__details {
  display: none;
}

.joblist__tr > tr:not(.joblist__tr__details) {
}

/*  */
.joblist__tr {
  padding: 20px 10px;
  margin: 10px 0px;
}
.joblist__cell--chevron {
  width: 3%;
  display: inline-block;
}


.joblist__cell--name,
.joblist__cell--id,
.joblist__cell--date,
.joblist__cell--manager,
.joblist__cell--expand {
  display: inline-block;
  width: 17.666%;
}

.joblist__cell--name {
  width: 35.3332%;
  display: inline-flex;
  /* flex-direction: column; */
}

.joblist__desc > div:first-child {
  display: flex;
  justify-content: space-between;
}

.joblist__cell--name > div:first-child {
  flex: 2;
}

.joblist__cell--name > div {
  flex: 1;
}

.joblist__cell--id {
}
.joblist__cell--date {
}

.joblist__cell--manager {
}

.joblist__cell--expand {
  width: 3%;
}

.joblist__cell--detail {
  transition: max-height 1s ease-in-out;
  max-height: 0;
  overflow: hidden;
}

.joblist__cell--detail--expand {
  max-height: 2000px;
}

.job__detail__container {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.joblist__cell--detail--expand > .job__detail__container {
  opacity: 1;
}

/* job__detail__container */
.job__detail__container {
  display: flex;
  flex-direction: column;
}

.job__detail__container > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job__detail--card div {
}

.job__detail--card div h3 {
  font-size: 1.5em;
}

.job__detail--card div h2 {
  color: rgb(224, 68, 41);
}
.job__detail--card > div {
  padding: 1.2em 2em;
  background: #EEF1F5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
}

.Card_img {
  width: 70px;
  height: 70px;
}

.job__detail__container--top {
  margin: 20px 0;
}

.job__detail__container--top > div {
  flex: 2;
}
.job__detail__container--top > div:nth-child(2) {
  margin: 0 20px;
}

.job__detail--card__xopa.job__detail--card > div{
  height: 250px !important;
}

.number__circle{
  border: 4px solid #16233A;
  border-radius: 50%;
  /* width: 100%;
  height: 100%; */
  width: 125px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number__circle h3{
  font-size: 2.5em;
}

.fs5em{
  font-size: 5em !important;
}
.fs4em{
  font-size: 4em !important;
}
.fs3em{
  font-size: 3em !important;
}
.fs2em{
  font-size: 2em !important;
}


.job__detail__container--top > div:first-child {
  flex: 3;
}

.job__detail__container--bottom > div {
  flex: 2;
}

.job__detail__container--bottom > div:nth-child(2) {
  margin: 0 20px;
}

.job__detail__container--bottom > div:nth-child(1) {
  flex: 2.5;
}
.job__detail__container--bottom > div:nth-child(2) {
  flex: 2.5;
}

.job__details {
  /* color: #3878e0; */
}

div.job__detail__container--top{
  align-items: stretch;
}

.job__detail__container--top > .job__detail--card > div {
  height: 100%;
}

.job__detail--card.job__detail--card--psych h2{
  position: relative;
  top: 10px;
  font-size: 3em;
}

@media screen and (max-device-width: 768px){
  .jobList .newJobBtn{
    display:none;
  }
  .jobList .btnNSort{
    margin-left: 0.075rem;
  }
}

.small_circle {
  height: 11px !important;
  width: 11px !important;
  border-radius: 100%;
  margin: 0 5px;
}

.joblist__cnt--total {
  background: #d3f6f3;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
}

.joblist__cnt--short {
  background: #fff3d5 !important;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
}

.joblist__cnt--rejected {
  background: #ffe6eb !important;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
}

.joblist__cnt--hired {
  background: #d6f8b8 !important;
  border-radius: 5px;
  padding: 10px;
  min-width: 100px;
}

.approved-color {
  color: green;
}

.rejected-color {
  color: red;
}

.pending-color {
  color: orange;
}
