.bx--modal__buttons-container {
    margin-top:-4%;
}


.talentPool,.xpav-gpt .leftNavFilter {
    margin-left: 1.5rem;
}

@media screen and (max-device-width: 768px) {
    .leftNavFilter {
        width:100%;
    }
}

@media screen and (min-device-width: 768px) {
    .leftNavFilter {
        /* max-width: 250px; */
    }

    .leftNavFilter .checkBoxElepsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 170px;
        display: inline-block;
    }
}