.lol {
  background-color: #fff;
  border-top: 1px solid #b9b9b9;
  box-shadow: 0 16px 24px 0 rgba(0,0,0,.08);
  height: 18.8125rem;
  left: 0;
  position: absolute;
  top: 96px;
  width: 100%;
  z-index: 1111;
}