/* .duration:hover, .analyticsBar:hover, .analyticsStackBar:hover, .analytics:hover{
    -webkit-box-shadow: -1px 9px 40px -12px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 9px 40px -12px rgba(0,0,0,0.75);
    box-shadow: -1px 9px 40px -12px rgba(0,0,0,0.75);
} */


.duration .bx--date-picker__icon{
    display:none
}

.duration .bx--date-picker__input{
    box-shadow: none!important;
}

.bx--date-picker__input:focus, .bx--date-picker__input.bx--focused{
    box-shadow: none!important;
}

.duration .filter{
    margin-top:-1%;
    margin-left:-1%;
}

.duration .period.selected{
    font-weight:bold;
    border-radius: 10px;
    background: #BEDFF6;
}
.duration .category.selected{
    font-weight:bold;
    border-radius: 10px;
    background: #BEDFF6;
}

.duration .category{
    padding-right:10px;
    padding-left:10px;
    color: #3d70b2!important;
}

.duration .period{
    padding-right:10px;
    padding-left:10px;
    color: #3d70b2!important;
}

/* .period-list div.periods a:first-child{
    padding-left:initial!important;
} */

.period-list div.periods a:last-child{
    border-right: 0px!important;
}

/* .category-list div.categories a:first-child{
    padding-left:initial!important;
} */

.category-list div.categories a:last-child{
    border-right: 0px!important;
}

.analyticsBar .barGraph{
    min-height: 100px!important;
}

.analyticsStackBar .recharts-tooltip-wrapper{
    z-index: 9999;
}

@media screen and (max-device-width: 768px) {
    
    /* .duration .bx--breadcrumb-item::after{
        content: '';
        margin-left: 0rem;
    }

    .duration .bx--breadcrumb-item{
        border-bottom: 1px solid #dee2e6!important;
    }

    .duration .bx--breadcrumb-item:last-child{ 
        border-bottom: none; 
        margin-right: 1rem;
    } */
  }
