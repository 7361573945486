.xpa-subheader .iconsweets {
    font-size: 16px;
    color: #EEF1F5;
    margin-right: 5px;
  }
  .xpa-subheader--menu-switcher {
    background: #d43f8d;
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .xpa-header--tabs .bx--tabs__nav-item a {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0.5em;
    margin: -0.5em;
  }

  .xpa-subheader select {
    background-color: #5558AF;
    border: none;
    box-shadow: 0 0 2px 2px rgb(91, 151, 229);
  }

  .xpa-subheader option {
    background-color: #7e81cc;
    border: none;
  }

  @media only screen and (max-width: 992px) {
    .switchcompany_toolbar {
      display: none;
    }
  }

  .logout_path {
    fill: none !important;
  }