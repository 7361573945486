label.dv-star-rating-star{
    font-size:1.2rem;
    padding-bottom: 0px;
    margin-bottom: 0px;    
}

.bx--modal-input-content {
    width: 50%;
}
.bx--structured-list-td.bx--structured-list-content--nowrap.bx--modal-header__label {
    width: 30%;
}

@media screen and (max-device-width: 768px) {
    .bx--accordion__content {
        padding: 0%;
    }
}

@media (max-width: 938px) {
    .small_dropdown {
        max-width: 250px !important;
    }

    .some-content .bx--accordion__content {
        padding-left: 0%;
    }
}