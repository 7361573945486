@media screen and (min-device-width: 768px) {
    
    .browseJobs .openCV{
        flex: 1;
        columns: 5;
    }
  }

  @media screen and (max-device-width: 576px) {
    .publicJobSearch .search-item {
      margin-bottom: 12px !important;
    }
  }

  @media screen and (max-device-width: 768px) {
    
    .browseJobs .openCV {
        flex:0;
        margin-top: 2%;
    }

    .publicJobSearch .search-item {
      margin-bottom: 12px !important;
    }

    .browseJobs .jobData {
        text-align: center;
    }

  }

  .publicJobSearch .search-item {
    margin-bottom: 12px !important;
  }

  .publicJobSearch .bx--search-input {
    height: 48px;
  }

  .searchDiv {
    background-image: url('../../../../assets/images/publicjobs.jpg');
    background-position: 50% 70%;
    background-size: cover;
  }

  .searchDiv .search-title {
    height: 60px !important;
    margin-left: 58px;
  }

  .searchDiv .searchBlock {
    background-color: rgba(0,0,0,0.2);
  }