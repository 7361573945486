
/* sui-layout sui-layout-header */
.companies-layout .sui-layout-header{
  padding: .5rem;
}

.xpa-avatar-color {
  background-color: black;
}

.btn--color:hover {
  color: white !important;
  text-decoration: unset !important;
}