.dragable-preference .dragPref_list .listPref{
  width: 100px !important;
  max-width: 100px !important;
  height: 120px !important;
  max-height: 120px !important;
  border-radius: 6px !important;
  margin: 0.5rem;
  padding: 0px !important;
  background-position: center;
  background-size: cover;
}

.items-list {
  border-radius: 6px !important;
  margin: 0.5rem;
  padding: 0px !important;
  background-position: center;
  background-size: cover;
  min-height: 140px !important;
}

.dragable-preference .dragPref_selected .selectedPref {
  border-radius: 6px !important;
  padding: 0px !important;
}

.dragPref_list, .dragPref_selected {
  border: 2px solid rgba(0,0,0,0.05) !important;
  box-shadow: 2px 2px rgba(0,0,0,0.1) !important;
}

.dragPref_selected .selectedPref {
  min-height: 120px;
  background-size: contain;
}

.xpa-selected-color {
  background-color: #eeeeee;
}

.dragPref_list {
  margin-right: 0.5rem !important;
}

.drag_job_matches {
  color: #bbb;
}