.xpa-customize-inline-notification .bx--inline-notification__icon {
  display: none;
}

.customize--bx--inline-notification-success__icon{
  fill: #24a148;
  margin-right: 5px;
  width: 18px;
}

.customize--bx--inline-notification-error__icon{
  fill: #da1e28;
  margin-right: 5px;
  width: 18px;
}



.xpa-app-card {
  border: 1px solid lightgray;
  background: whitesmoke;
  padding: 5px;
  border-radius: 5px;
  margin: 0 .5rem;
}

.xpa-bx-col-m{
  margin: .5rem 0;
}

.xpa-customize-inline-notification .bx--inline-notification__text-wrapper {
  /* display: -webkit-box; */
  /* flex-wrap: wrap; */
  display: block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .75rem 0;
  width: 100%;
}

.xpa-customize-inline-notification .bx--type-delta{
  font-size: 1.125rem;
}

.xpa-customize-inline-notification .xp-avatar-25{
  top: -2px;
}

.xpa-customize-inline-notification .bx--inline-notification__title {
  margin-bottom: 1rem;
  display: block;
  font-size: 1.1rem
}

.xpa-customize-inline-notification .bx--row{
  padding: 0 1rem;
}

@media (min-width: 320px) and (max-width: 480px) {
  #table-layout {
    display: block;
  }
  .card-layout {
    display: none;
  }
  .xpa-appl-info--list .bx--table-toolbar {
    display: block;
  }
  .xpa-appl-info--list .bx--toolbar-content {
    margin-top: 0.6rem;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  #table-layout {
    display: block;
  }
  .card-layout {
    display: none;
  }
  .xpa-appl-info--list .bx--table-toolbar {
    display: block;
  }
  .xpa-appl-info--list .bx--toolbar-content {
    margin-top: 0.6rem;
  }
}

@media (min-width: 767px) {
  .card-layout {
    display: none;
  }
  .xpa-appl-info--list .bx--table-toolbar {
    display: flex;
  }
}

.badge-space {
  margin: 5px;
  padding: 5px;
}

.xopa__table__new {
  background: white;
}

.xopa__table thead tr {
  text-transform: uppercase;
  font-size: 0.7em;
  /* vertical-align: top; */
  vertical-align: text-top;
}

.xopa__table thead tr td {
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}

.xopa__table thead tr td {
  text-align: center !important;
}

.xopa__table thead tr td:first-child {
  padding-left: 10px;
}

.xopa__table thead tr td:last-child {
  padding-right: 10px;
}

.xopa__table thead tr {
  background-color: #fff;
  /* color: white; */
  color: rgba(0, 0, 0, 0.54);
  border-radius: 10px;
}

.xopa__table td {
  text-align: center;
  /* cursor: pointer; */
}

.xopa__table tbody td {
  padding-right: 8px;
}

.xopa__table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
}

/* xopa__row */

.xopa__row {
  background-color: white;
  border-radius: 5px;
}

.xopa__row td {
  display: table-cell;
  vertical-align: middle;
}

/* cells */

.cell__name {
  padding-left: 20px;
}

/* cell__shortlist */

.cell__shortlist__inner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}

.cell__shortlist__inner>div {
  box-sizing: border-box;
  border: 3px solid #EEF1F5;
  padding: 0 10px;
  cursor: pointer;
  font-size: 0.7em;
  border-radius: 4px;
}

.cell__shortlist__inner>div:first-child {
  border-color: #4c9c07;
  color: #4c9c07;
}

.cell__shortlist__inner>div:nth-child(2) {
  border-color: #eb757b;
  color: #eb757b;
}

.RC__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RC__container>span {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #4c9c07;
  width: 35px;
  height: 35px;
  border-radius: 17.5px;
}

.RC__value {
  font-size: 0.8em;
}

.cell__name>div {
  display: inline-block;
}

.cell__name {}

.xopa__table td.cell__name {
  text-align: left;
  /* font-size: 11px; */
  text-transform: capitalize;
}

.xpa-appl-info--list .bx--data-table-v2-container {
  min-width: unset;
}

.xpa-appl-info--list .bx--data-table-v2-container .bx--tile {
  padding-bottom: 4px;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.pointer {
  cursor: pointer !important;
}

.candidates_list .bx--modal-header {
  margin-bottom: 0px;
}

.candidates_list .bx--modal-header__heading {
  margin-bottom: 0px;
}

.left-navigation {
  flex-basis: 4.7% !important;
}

.newmain-style {
  padding-top: 62px !important;
}

.newpagediv-style {
  margin-bottom: 0% !important;
  flex-basis: 95.3% !important;
  background: #f2f2f4 !important;
}

.component-container {
  height: -webkit-fill-available !important;
  width: -webkit-fill-available !important;
}

.leftcards-container {
  background: #fff;
  color: black;
  overflow-y: scroll;
  height: calc(100vh - 64px);
}

.rightdetails-container {
  background: #f2f2f4;
}

.left-card {
  background: rgba(0, 0, 0, 0, 0.9) !important;
  margin-top: 2px !important;
  border-bottom: 1px solid #4c4c4c;
}

.left-card:hover {
  background-color: #bebece !important;
}

.application-iframe {
  height: 100vh;
  width: 100%;
}