.font_head{
    font-size: 1rem !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
    font-family: 'Roboto', sans-serif !important;
    color: black !important;
}
.hed_secnd{
    font-size: 80% !important;
    font-weight: 700!important;
    text-transform: uppercase!important;
    font-family: 'Heebo', sans-serif!important;
}
.stp_img{
    border-radius: 50%;
    width: 120px;
}

.font_hed_stdprof{
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    line-height: 1.5 !important;
    font-family: 'Roboto', sans-serif !important;
    color: #000 !important;
}

.Profpic_area{
    width: 400px;
}
.disp_img{
    display: inline-block;
}
.std_name{
    position: relative;
    bottom:2rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif !important;
    color: #000 !important;
}
.left_sapace{
    margin-left: 20px;
}
.pos_btn{
    display: inline-block;
}
.bt_sub_pos{
    position: relative;
    left: 455px;
    bottom: 144px;
}

.upload-docs-min-width {
	min-width: 182px;
}