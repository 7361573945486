.applicants-layout .sui-layout-header {
    padding: .5rem;
}

.fnt-size{
    font-size: large;
}

.bx--action-list .bx--btn{
    padding: calc(0.875rem - 3px) 40px calc(0.875rem - 3px) 12px;
}