.xpa-customize .bx--btn--sm {
    min-width: 120px;
    min-height: 2rem;
    padding: calc(0.375rem - 3px) 20px calc(0.375rem - 3px) 12px;
    /* margin-right: 20px; */
}

.xpa-customize-dropdown .css-1hwfws3 {
    max-width: 145px;
}

.student-profile-view-layout .sui-layout-header {
    padding: 0rem;
    border-bottom: none;
}