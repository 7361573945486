/* main dashboard */
.xpa-pg-candidate--dashboard {
}
.xpa-pg-candidate--dashboard .toastCandidate div:first-child{
  z-index: 99999;
}
.xpa-pg-candidate--dashboard .bx--tile {
  cursor: pointer;
  padding: 1rem 0rem 0rem 0rem;
}

.xpa-f-name-sz-width {
  min-width: max-content;
}

.display-tabs-at-end .bx--tabs__nav {
  margin: 0%;
  justify-content: flex-end
}

.job__details-button-height {
  max-height: 25px;
}

.xpa-banner-color {
  background-color: black;
  color: white;
}
