.labels-color .bx--side-nav__link .bx--side-nav__link-text {
  color: white;
}

.labels-color .bx--side-nav__link .bx--side-nav__link-text svg {
  fill: white;
}

.labels-color {
  color: white;
}

.labels-color svg {
  fill: white;
}