.xpa-c-backrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #5558AF;
}
.xpa-c-backrow .bx--link {
  cursor: pointer;
  font-size: 0.8rem;
  color: #5558AF !important;
}
.xpa-c-backrow .xpa-c-backrow--item * {
  font-size: 0.8rem;
}

.backrow-name {
  cursor: pointer;
}

.backrow-name .bx--link {
  color: #5558AF !important;
}