.counter {
  width: 150px;
  height: 40px;
  display: flex;
}

.counter > span:nth-child(2) {
  flex: 1;
  background: white;
  color: #632763;
}

.counter > span {
  background-color: #632763;
  color: white;
  height: 40px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.shortlist__header__right{
  display: flex;
  
}
.shortlist__header__right > input {
  background: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid #632763;
  margin-right: 20px;
}