/* .App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
} */

.Toastify__toast--error {
  border-left: 3px solid #da1e28;
  background: #fff1f1;
  color: #161616;
}
.Toastify__close-button--error,
.Toastify__close-button--success,
.Toastify__close-button--info {
  color: #161616;
}
.Toastify__toast--success {
  border-left: 3px solid #24a148;
  background: #defbe6;
  color: #161616;
}
.Toastify__toast--info {
  background: #edf5ff;
  border-left: 3px solid #0043ce;
  color: #161616;
}
.Toastify__progress-bar {
  background-color: #393939ba;
}