.finalOfferDetails {
  display: flex;
}

.finalOfferDetails__innter {
  flex: 1;
  padding: 0 3em;
}

.finalOfferDetails__cardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3em;
  grid-row-gap: 1em;
}

