.bio_info .bx--accordion__item--active .bx--accordion__content {
  padding-top: 0%;
}

.bio_info .bx--accordion__content {
  padding: 0 1rem 0 2rem;
}

.numInputWrapper .numInput.cur-year {
  min-width: 52px !important;
}