div .circle {
  background: #108ee9;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.7em;
  margin-right: 5px;
  text-align: center;
  width: 1.7em;
}