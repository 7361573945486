.FinalOfferCard {
  padding: 1em;
  background: #eee;
  border-radius: 7px;
}

.FinalOfferCard__detailsContainer {
  display: flex;
  padding-bottom: 1em;
}

.FinalOfferCard__profileImg {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FinalOfferCard__details {
  flex: 4;
}

.FinalOfferCard__profileImg > img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #eb757b;
}

.FinalOfferCard__details th {
  padding: 0.2em 1em;
}

.FinalOfferCard__details td {
}

/* decision */
.FinalOfferCard__decision {
  display: flex;
  border-top: 2px solid #16233A;
}

.FinalOfferCard__decision > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding-top: 0.5em;
  padding-bottom: 1em;
}
.FinalOfferCard__decision > div > span { 
  padding-bottom: 0.5em ;
}

.FinalOfferCard__decision__icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FinalOfferCard__decision > div:first-child {
  border-right: 2px solid #16233A;
}

.FinalOfferCard__decision__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.FinalOfferCard__decision__icon--check {
  background-color: #4c9c07;
}
.FinalOfferCard__decision__icon--seperator {
  color: #16233A;
  margin: 0 0.7em;
  cursor: default;
}
.FinalOfferCard__decision__icon--times {
  background-color: #eb757b;
}

