.video-iframe {
  width: 100%;
  min-width: 700px;
  background:none !important;
  border: none;
  display:block;
  margin: 0 auto;
  /* height: 100vh; */
}
.text-question {
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#rr-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.xpa-form--create-interview {
  max-width: 600px;
}
.xpa-video--answers .bx--accordion__title{
    width: 100%;
}
.xpa-video--answers .bx--accordion__heading {
  background: #F3F4F5;
}

.xpa-tabs-tile-width {
  max-width: 390px;
}

.xpa-font-size-emoji-width {
  font-size: 15px;
}