.gtp-avatar {
  background: #f6eff0;
  width: 48px;
  height: 48px;
  max-width: 48px;
}

@media screen and (max-device-width: 768px) {
  .xpav-gpt img{
    width: 60px;
    height: 60px;
    margin-left: 1rem;
    margin-bottom: 10px;
  }

  .bx--data-table-v2-container{
    min-width: inherit;
  }
  .xpav-gpt .openCVBtn{
    text-align:center;
    position: inherit;
  }

  .gtpcandidateInfo{
    text-align: center;
  }
}

@media screen and (device-width: 768px) {
  .xpav-gpt .openCVBtn{
    margin-left: 40%;
  }
}

@media screen and (min-device-width: 768px) {
  .xpav-gpt img{
    width: 100px;
    height: 100px;
  }
  .xpav-gpt .openCVBtn{
    display:flex;
  }

  .xpav-gtp .openCVBtn a{
    margin-left: 3rem;
  }

  .xpav-gtp .SearchGtp{
    width:75%;
  }
}

/* .iscard.details {
  display: flex;
  align-items: center
} */