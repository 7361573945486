.xpa--captcha-err{
  color: red;
  font-size: .7rem;
}

.xpa--login .nav-tabs .nav-item {
  border: none !important;
}
.xpa--login .form-signin {
  border: none;
  border-radius: 0px;
}
.xpa--login .nav-tabs {
  border-bottom: 0px;
}
.xpa--login .form-signin {
  width: 330px;
}

.grecaptcha-badge{
  top: 100px;
}

.bg-trans {
  position: absolute;
  width: 100%;
  background-color: #ABB0B8;
  /* background-image: linear-gradient(to top, #d43f8d 0%, #086acc 100%); */
  height: 100%;
  left: 0;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
  height: 269px;
}
.form-signin.error {
  height: 311px;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.bx--text-input{
  min-width: 100%;
}

.login-page {
  min-height: -webkit-fill-available;
  min-height: 100vh !important;
}

.login-page .left-side {
  background: #5558AF;
  background-size: cover;
  background-position: 50%;
  background-color: '#5558AF';
  color: white !important;
  min-height: -webkit-fill-available;
  min-height: 100vh;
}

.login-page .right-side {
  background: #ffff;
  background-size: cover;
  color: black;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.login-page .middle-center {
  background: #80a2ce;
  background-size: cover;
  color: darkblue;
  min-height: -webkit-fill-available;
}

.login-page .xpa--login .form-signin {
  height: auto;
}

.recruiterlogin.login-page .left-side {
  background: #5558AF;
  background-image: url('../../../assets/images/recruiter-login.jpg');
  background-size: cover;
  background-position: 50%;
  background-color: '#5558AF';
  color: white;
  min-height: -webkit-fill-available;
}

.recruiterlogin.login-page .right-side{
  background: whitesmoke !important;
  min-height: -webkit-fill-available;
}

.professionallogin.login-page .left-side{
  background: #ffff;
  background-image: url('../../../assets/images/publicjobs.jpg');
  background-size: cover;
  color: white;
  min-height: -webkit-fill-available;
}

.professionallogin.login-page .right-side{
  background: whitesmoke !important;
  min-height: -webkit-fill-available;
}

.login-list ol {
  list-style: disc;
  list-style-type: disc !important;
  padding-left: 1em;
}

.login-list li {
  list-style: inherit !important;
  list-style-position: 'inside';
  text-align: start;
  margin: 20px;
}

.login-page .login-candiate-button {
  color: black;
  border-color: black;
}

.login-page .login-candiate-button:hover {
  color: white;
  border-color: white;
}

.xpa-reg-content {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../../assets/images/background/signup.jpeg');
}
.std_heding{
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase!important;
  font-family: 'Roboto', sans-serif !important;
  color: black !important;
}