@media only screen and (max-width: 460px) {
  .xs-fields-device {
    width: 100% !important;
  }
}

@media only screen and (min-width: 550px) and (max-width: 710px) {
  .sm-fields-device {
    width: 75% !important;
  }
}

@media only screen and (min-width: 711px) and (max-width: 810px) {
  .lg-fields-device {
    width: 65% !important;
  }
}

.input-width .bx--select-input__wrapper {
  width: 100%;
}