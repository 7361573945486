.app-sidebar.newjob__sidebar {
  padding: 0;
  width: 300px;
  background-image: url('newjob_sidebar.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.xpa-customize-radio .add-border {
  border-width: .2rem;
  border-color: lightgrey;
  border-style: solid;
  border-radius: .2rem;
  padding: .5rem;
}

.newjob__sidebar {
  text-align: center;
}

.newjob__sidebar>img {
  width: 90%;
  z-index: 2;
}

.role-box {
  padding-left: 30px;
}

.newjob__sidebar .whiteoverlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(239, 242, 247, 0.5);
}

.newjob_page {
  display: flex;
}

.newjob__form {
  padding-bottom: 2em;
}

.jewjob_right_sidebar {
  background: #EEF1F5;
  padding: 0 10px;
  padding-top: 200px;
  color: #1a1a1a;
  max-width: 350px;
}

.jewjob_right_sidebar h3 {
  font-size: 1.2em;
  margin-bottom: 1em;
}

.jewjob_right_sidebar ul {
  padding: 0;
  margin: 0;
}

.jewjob_right_sidebar ul li {
  list-style-type: none;
  margin: 5px 0 5px 5px;
  color: #9a9a9a;
}

.jewjob_right_sidebar p {
  margin-top: 1em;
}

.newjob__form {
  flex: 1;
  padding-left: 1em;
  padding-right: 1em;
}

.flexRow {
  display: flex;
  align-items: center;
}

.newjob__form__header {
  padding: 1em 0 0 0;
}

.newjob__form__header--center {
  text-align: center;
}

.newjob__form__header--center h3, .newjob__form__header--center h5 {
  display: inline-block;
}

.newjob__form__header h3 {
  font-size: 1.5em;
  color: #232323;
}

.newjob__form__header p {
  color: #9a9a9a;
}

.newjob__form .xpa-banner {
  background: #F3F4F5;
}

.newjob_input_container {
  width: 550px;
  max-width: 550px;
  display: flex;
  margin: .4em 15px .4em 0;
}

.newjob_input_container>input {
  flex: 1;
  border-radius: 5px;
  outline: none;
  border: 1px solid #bbb;
  padding: 0.3em 0.5em;
  display: flex;
  align-items: center;
}

.newjob_input_container>input:nth-child(2) {
  margin-left: 0.7em;
}

.newjob_input_container>input:required {
  box-shadow: none;
}

.newjob_input_container>input:invalid {
  box-shadow: none;
}

/* .newjob_desc_container {
  width: 70%;
} */

.newjob_desc_container>textarea {
  flex: 2;
  border-radius: 5px;
  border: 1px solid #bbb;
  padding: 0.5em 1em;
}

.newjob_desc_container>textarea:required {
  box-shadow: none;
}

.newjob_desc_container>textarea:invalid {
  box-shadow: none;
}

.end_date {
  padding: 0.3em 1em;
  width: 100%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 5px;
}

.newjob_desc_container>div {
  flex: 1;
  /* margin-left: 1em; */
  max-width: 100%;
}

.newjob_upload {
  margin-bottom: 0.5em;
}

.newjob_upload>span:nth-child(2) {
  margin-left: 0.5em;
}

.newjob_input_container>.education, .flexRow>.industry, .flexRow>.terms, .flexRow>.seniority, .flexRow>.hours, .flexRow>.type, .flexRow>.experience, .flexRow>.function, .flexRow .salary__from, .flexRow .salary__to, .flexRow .salary__currency {
  flex: 1;
  /* margin-right: 15px; */
}

.flexRow .salary__currency {
  /* margin-right: 0.7em; */
}

.flexRow>.salary__to {
  margin: 0 0.7em;
}

/* .flexRow > .experience,
.flexRow > .function {
  margin: 0 0.7em;
} */

.flexRow .salary__from, .flexRow .salary__to, .flexRow .salary__currency {}

.hiring__team {
  display: flex;
  width: 450px;
  margin-left: 50px;
}

.hiring__team>div {
  display: flex;
  flex-direction: column;
  padding-top: 7px;
  padding-bottom: 7px;
}

.hiring__team__col2>span {
  display: inline-block;
  border: 1px solid #bbb;
  border-left-color: transparent;
  border-right-color: transparent;
  padding-left: 10px;
  box-shadow: 10px 0 8px -2px #eee;
}

.hiring__team__col2 {
  flex: 1;
  margin: 0 1em;
}

.hiring__team__col3>div {
  cursor: pointer;
  width: 35px;
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hiring__team__col1> :first-child, .hiring__team__col2> :first-child, .hiring__team__col3>* {
  margin-bottom: 10px;
}

.newjob__designation {
  background: #e0e0e0;
  color: #4f4d4e;
  padding: 1em;
  margin-left: -20px;
}

.newjob__designation input {
  flex: 1;
  margin: 0 1em;
  background-color: transparent;
  border: none;
  box-shadow: 3px 0 8px -2px #4f4d4e;
  outline: none;
  height: 45px;
  padding: 10px;
  color: white;
  font-size: 1.2em;
}

.newjob__savebuttons {
  margin-top: 2em;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  justify-content: space-around;
}

.newjob__savebuttons button {
  width: 220px;
  height: 45px;
  background: #e0e0e0;
  color: #4f4d4e;
  border: none;
  outline: none;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: bold;
}

.deadline_vacNo, .newjob__vacNo {
  flex: 1;
}

.newjob__vacNo {
  margin: 0 1em;
}

.newjob_education_requirement {
  display: flex;
  justify-content: space-around;
  margin-left: -2em;
}

.newjob_education_requirement>div {
  display: flex;
  flex-direction: column;
}

.newjob_education_requirement>div>label {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.1em;
}

.newjob_education_requirement>div>label span {
  color: #5d5d5d;
}

.newjob_education_requirement>div span {
  margin-left: 8px;
}

.fa.check, .fa.nocheck {
  font-size: 1.2em;
}

.newjob_education_requirement input {
  display: none;
}

.newjob_education_requirement input~.check {
  display: none;
}

.newjob_education_requirement input~.nocheck {
  display: inline;
}

.newjob_education_requirement input:checked~.nocheck {
  display: none;
}

.newjob_education_requirement input:checked~.check {
  display: inline;
}

.newjob__designation .add {
  text-decoration: underline;
  color: black;
}

.skill_input_dropdown {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.skill_input_dropdown {
  position: relative;
}

.skill_input_dropdown>.skill_dropdown {
  position: absolute;
  top: 37px;
  background: white;
  z-index: 2;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}

.skill_input_dropdown p {
  padding-bottom: 0px;
  margin-bottom: 0px;
  color: #086acc;
}

.skill_dropdown>div {
  padding: 0.5em;
  cursor: pointer;
  font-size: 1.2em;
}

.skill_dropdown>div:hover {
  background-color: #f0f1f4;
  color: white;
}

.Select-menu {
  background-color: #f0f1f4;
}

.Select-option {
  color: #086acc;
}

.hidden {
  display: none !important;
}

.jdmodal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: all;
  z-index: 10001;
}

.jdmodal__body {
  width: 400px;
  height: 300px;
  background-color: #f0f1f4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jdmodal__body h3 {
  color: #086acc;
}

.anchor-color {
  color: #086acc;
}

.company-edit {
  margin-left: 1em;
  margin-top: 0.35em;
}

.ql-editor {
  min-height: 20em;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* .bx--checkbox-wrapper {
  margin-left:26%;
} */

.DayPickerInput input {
  color: #16233A;
  background-color: #F3F4F5;
  border: none;
  box-shadow: 0 1px 0 0 #525C6D;
  height: 2rem !important;
}

.newjob__form .bx--slider__thumb {
  z-index: 0;
}

.xpa-job-title {
  color: red
}

.accr-wdth .bx--accordion__content {
  padding-right: 0%;
}

.accordion-content .bx--accordion__content {
  padding-right: 0px;
}