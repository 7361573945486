.dropdown .education.light > :first-child {
  font-family: "ibm-plex-sans",Helvetica Neue,Arial,sans-serif;
	font-size: 0.875rem;
    width: 100%;
    height: 2.5rem;
    border-radius: initial;
    color: #16233A;
    background-color: white;
    border: none;
    /* box-shadow: 0 1px 0 0 #525C6D; */
    /* order: 2; */
    border-bottom: 1px solid transparent;
}

.dropdown .education.search > :first-child {
  font-family: "ibm-plex-sans",Helvetica Neue,Arial,sans-serif;
	font-size: 0.875rem;
    width: 100%;
    height: 48px;
    border-radius: initial;
    color: #16233A;
    background-color: white;
    border: none;
}

.dropdown .education.none > :first-child {
  font-family: "ibm-plex-sans",Helvetica Neue,Arial,sans-serif;
	font-size: 0.875rem;
  width: 100%;
}

.dropdown .education.default > :first-child {
  font-family: "ibm-plex-sans",Helvetica Neue,Arial,sans-serif;
	font-size: 0.875rem;
    width: 100%;
    height: 2.5rem;
    border-radius: initial;
    color: #16233A;
    /* background-color: #F3F4F5; */
    /* border: none; */
    /* box-shadow: 0 1px 0 0 #525C6D; */
    /* order: 2; */
    /* border-bottom: 1px solid transparent; */
    /* border: none; */
}

.dropdown .css-kj6f9i-menu {
  z-index: 10;
}

.disabled-select {
  cursor: not-allowed !important;
}

.disabled-label {
  color: #c6c6c6 !important;
}

.dropdown .css-151xaom-placeholder {
  margin-left: 3px;
}