/* main dashboard */
.xpa-pg-roboroy--dashboard {
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  flex: 1;
}

.xpa-pg-roboroy--dashboard .bx--tile {
  cursor: pointer;
  padding: 1rem 0rem 0rem 0rem;
}

.bx--tabs__nav {
  margin: 0%;
}

.display-end .bx--tabs__nav {
  margin: 0%;
  justify-content: flex-end
}

.xpa-tile-size {
  max-width: 15rem;
  max-height: 7rem;
}

.xpa-f-sz {
  font-size: 12px;
}

.xpa-f-sz-width {
  min-width: max-content;
}

.xpa-avatar-width {
  min-width: 30px;
}

.xpa-companies-view-border {
  border-left: 3px solid rgb(204, 159, 61)
}

.xpa-institutions-view-border {
  border-left: 3px solid rgb(61, 204, 141);
}

.xpa-students-view-border {
  border-left: 3px solid rgb(254, 103, 48);
}

.xpa-students-info-div div {
  border: 2px solid black;
}

