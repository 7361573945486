.xpa-modal--resume-upload.xpa-modal {
    position: fixed !important;
    width: 30%;
    height: 30%;
}

.xpa-modal--resume-upload .xpa-modal__body {
    padding: 0.5em 1.5em;
    position: absolute;
    top: 50%;
    transform: translate(-0%, -25%);
}