.card-invite {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 20px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(to top, #9795f0 0%, #fbc8d4 100%); */
  height: 100%;
  min-height: 100vh;
}
.card-invite .card-title {
  font-weight: 700;
}
.card-invite .m-logo-wrap {
  text-align: center;
  margin-bottom: 15px;
}
.card-invite .m-logo {
  height: 60px;
}
.card-invite .card {
  padding: 1rem;
  border-top: 4px solid #632763;
}
.card-invite .card-content {
  border-radius: 0 0 2px 2px;
  width: 100%;
  max-width: 100%;
}
.card-invite .card-subtitle {
  margin-bottom: 15px;
}
.card-invite .title-register {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}
.card-invite .jc-card {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-invite .column {
  flex: 1;
}
.card-invite .linkedin-wrap {
  cursor: pointer;
}
.card-invite .linkedin-wrap img {
  max-width: 210px;
}

.invite-company {
  align-items: center;
  flex-direction: column;
}

.invite-content {
  padding-left: 0;
  padding-right: 0;
  position: relative;
  height: 100%;
}

@media only screen and (min-width: 800px) {
  .card-invite .card-content {
    border-radius: 0 0 2px 2px;
    margin: 20px;
    width: 650px;
  }
  .card-invite .invite-company {
    flex-direction: row;
    border-left: 1px solid #ccc;
    padding-left: 20px;
    border-top: none;
  }
  .card-invite .company-wrap {
    border-top: 1px solid #eee;
  }
}
@media (max-width: 960px) {
  .invite-content {
    width: 100%;
  }
}
@media only screen and (max-width: 320px) {
  .card-invite .card .card-title {
    font-size: 18px;
  }

  .card-invite .title-register {
    font-size: 20px;
  }
}